import React from 'react';
import PropTypes from 'prop-types';

export const IconGiftWithCircle = ({ className }) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-gift-with-circle-clip0_7359_33977)">
      <circle opacity="0.1" cx="18" cy="18" r="18" fill="url(#icon-gift-with-circle-paint0_linear_7359_33977)" />
      <path
        d="M16.1297 28.6172H19.8703V12.3791H16.1297V28.6172ZM22.6611 11.13C23.2598 10.2361 23.6109 9.16242 23.6109 8.00733C23.6109 7.66209 23.3321 7.38281 22.9875 7.38281C20.8188 7.38281 18.9339 8.62151 18 10.4299C17.066 8.62151 15.1811 7.38281 13.0125 7.38281C12.6679 7.38281 12.3891 7.66216 12.3891 8.00733C12.3891 9.16242 12.7402 10.2361 13.3389 11.13H22.6611ZM21.1172 28.6172H25.4812C25.8259 28.6172 26.1047 28.3378 26.1047 27.9927V19.8736H21.1172V28.6172H21.1172ZM9.89529 27.9927C9.89529 28.3379 10.1741 28.6172 10.5188 28.6172H14.8828V19.8736H9.89536V27.9927H9.89529ZM8.64844 13.0037V18C8.64844 18.3452 8.9273 18.6245 9.2719 18.6245H14.8828V12.3791H9.2719C8.9273 12.3791 8.64844 12.6584 8.64844 13.0037ZM26.7281 12.3791H21.1172V18.6245H26.7281C27.0727 18.6245 27.3516 18.3452 27.3516 18V13.0037C27.3516 12.6584 27.0727 12.3791 26.7281 12.3791Z"
        fill="white"
      />
    </g>
  </svg>
);

IconGiftWithCircle.propTypes = {
  className: PropTypes.string,
};

IconGiftWithCircle.defaultProps = {
  className: '',
};
