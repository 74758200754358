import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './deposit-button-mobile.scss';
import Button from 'reactstrap/lib/Button';
import { withModalActions, withUser } from 'core/hocs';
import { USER_FIELDS } from 'core/constants';
import { IconPlus } from 'components/icons/icon-plus/icon-plus';
import { MODAL_IDS } from '../../constants';

const setLayout = colums => document.documentElement.style.setProperty('--navigation-items', `${colums}`);

export const DepositButtonMobileUI = ({
  openModal, isSignUpCompleted, isUserLoggedIn, isPwa
}) => {
  const handleModalOpen = useCallback(() => {
    openModal(isSignUpCompleted
      ? MODAL_IDS.DEPOSIT
      : MODAL_IDS.COMPLETE_SIGN_UP);
  }, [isSignUpCompleted]);

  useEffect(() => {
    const navItemsCount = isUserLoggedIn ? 5 : 4;

    setLayout(navItemsCount);

    return () => setLayout(4);
  }, [isUserLoggedIn]);

  return (
    isUserLoggedIn
    && (
      <div className={classNames('d-flex justify-content-center', { 'align-items-end': !isPwa })}>
        <Button
          onClick={handleModalOpen}
          color="transparent"
          className="deposit-button d-flex justify-content-center align-items-center"
          size="xs"
        >
          <div className="deposit-button-border d-flex justify-content-center align-items-center rounded-circle ">
            <div className="deposit-button-content d-flex justify-content-center align-items-center rounded-circle">
              <IconPlus size="32" />
            </div>
          </div>
        </Button>
      </div>
    )
  );
};

DepositButtonMobileUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isSignUpCompleted: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  isPwa: PropTypes.bool.isRequired,
};

DepositButtonMobileUI.defaultProps = {};

export const DepositButtonMobile = withModalActions(withUser(
  DepositButtonMobileUI, [USER_FIELDS.IS_SIGN_UP_COMPLETED, USER_FIELDS.IS_USER_LOGGED_IN]
));
