module.exports = {
  firstName: {
    type: 'text',
    label: 'form.fields.first-name',
    step: 1,
    validation: [
      { type: 'required' },
      { type: 'latin-words-with-spaces-and-dashes-and-special-chars' },
      { type: 'max-length', value: 50 },
    ],
  },
  lastName: {
    type: 'text',
    label: 'form.fields.last-name',
    step: 1,
    validation: [
      { type: 'required' },
      { type: 'latin-words-with-spaces-and-dashes-and-special-chars' },
      { type: 'max-length', value: 50 },
    ],
  },
  gender: {
    type: 'select',
    label: 'form.fields.gender',
    placeholder: 'placeholder.select',
    step: 1,
    validation: [{ type: 'required' }],
  },
  year: {
    type: 'select',
    placeholder: 'form.fields.year',
    step: 1,
    validation: [{ type: 'required' }, { type: 'separate-birth-date' }],
    isReadOnly: true,
  },
  month: {
    type: 'select',
    placeholder: 'form.fields.month',
    step: 1,
    validation: [{ type: 'required' }],
    isReadOnly: true,
  },
  day: {
    type: 'select',
    placeholder: 'form.fields.day',
    step: 1,
    validation: [{ type: 'required' }],
    isReadOnly: true,
  },
  docNumber: {
    type: 'text',
    label: 'form.fields.national-id',
    validation: [
      { type: 'required' },
      { type: 'national-registration-number' },
    ],
    step: 1,
  },
  mobile: {
    type: 'tel',
    label: 'form.fields.mobile',
    placeholder: 'form.fields.mobile',
    step: 1,
    validation: [{ type: 'required' }],
  },
  city: {
    type: 'text',
    label: 'form.fields.city',
    step: 1,
    validation: [
      { type: 'required' },
      { type: 'latin-words-with-spaces-and-dashes-and-special-chars' },
      { type: 'max-length', value: 30 },
    ],
  },
  postalCode: {
    type: 'text',
    label: 'form.fields.postal-code',
    step: 1,
    validation: [{ type: 'required' }, { type: 'latin-and-special-symbols-with-number' }, { type: 'max-length', value: 10 }],
  },
  address: {
    type: 'text',
    label: 'form.fields.address',
    validation: [{ type: 'required' }, { type: 'max-length', value: 75 }],
    step: 1,
  },
};
