import React from 'react';
import PropTypes from 'prop-types';

export const IconInfoDeposit = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8067_3644)">
      <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 20 0)" fill="url(#paint0_linear_8067_3644)" />
      <g clipPath="url(#clip1_8067_3644)">
        <path
          d="M10.0002 15.8847C10.6502 15.8847 11.1772 15.3578 11.1772 14.7077V9.9999C11.1772 9.34988 10.6502 8.82294 10.0002 8.82294C9.35019 8.82294 8.82324 9.34988 8.82324 9.9999V14.7077C8.82324 15.3578 9.35019 15.8847 10.0002 15.8847Z"
          fill="white"
        />
        <path
          d="M10.0002 6.46918C9.35019 6.46918 8.82324 5.94223 8.82324 5.29222C8.82324 4.6422 9.35019 4.11525 10.0002 4.11525H10.0115C10.6615 4.11525 11.1885 4.6422 11.1885 5.29222C11.1885 5.94223 10.6615 6.46918 10.0115 6.46918H10.0002Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

IconInfoDeposit.propTypes = {
  className: PropTypes.string,
};

IconInfoDeposit.defaultProps = {
  className: '',
};
