/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { FormattedTag } from 'core/components';
import {
  withBonuses,
  withGlobalEvents,
  withModalActions,
} from 'core/hocs';
import {
  convertUTCToISOString,
  getIntlKeyByEnv,
  isPast,
} from 'core/helpers';
import {
  BONUS_STATUS,
  GLOBAL_EVENTS,
} from 'core/constants';
import { BonusReminderDetailsModal } from 'components/modals/bonus-reminder-details-modal/bonus-reminder-details-modal';
import { IconClose } from 'components/icons/icon-close/icon-close';
import { IconGiftWithCircle } from 'components/icons/icon-gift-with-circle/icon-gift-with-circle';
import { BonusReminderWidgetTimer } from './bonus-reminder-widget-timer';
import { parseJson } from '../../helpers/json';
import { BONUS_REMINDER_DISPLAY_DATE_AND_TIME_LS_KEY, MODAL_IDS } from '../../constants';

import './bonus-reminder-widget.scss';

const { SUCCESS_COMPLETE_PAYMENT_PROCESS } = GLOBAL_EVENTS;

const BonusReminderWidgetUI = ({
  allBonuses: {
    isInProgress: isAllBonusesInProgress,
    items: allBonusesItems,
  },
  depositBonuses: {
    isInProgress: isDepositBonusesInProgress,
    items: depositBonusesItems,
  },
  getAllBonuses,
  getDepositBonuses,
  clearAllBonuses,
  clearDepositBonuses,
  globalEvents,
  hasNavigation,
  intl,
  isCookiesPolicyAccepted,
  isSignUpCompleted,
  // isSportsbookPage,
  locale,
  openModal,
  openModalAndCloseOthers,
  userData: {
    regDate,
  },
}) => {
  const bonusReminderConfig = parseJson(
    intl?.formatMessage({ id: getIntlKeyByEnv('bonus-reminder.config') }),
    'Error has occured while parsing bonus remider config json',
    {}
  );
  const { bonusId, timerExpiry = 15 } = bonusReminderConfig;

  const expiryDate = dayjs(convertUTCToISOString(regDate)).add(timerExpiry, 'minute').toString();
  const displayDateAndTimeFromLs = window.localStorage.getItem(BONUS_REMINDER_DISPLAY_DATE_AND_TIME_LS_KEY) || '';

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isExpired, setIsExpired] = useState(isPast(expiryDate));
  const [displayDateAndTime, setdisplayDateAndTime] = useState(displayDateAndTimeFromLs);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(prev => !prev);
  }, []);

  const openDepositModal = useCallback(() => {
    openModalAndCloseOthers(isSignUpCompleted
      ? MODAL_IDS.DEPOSIT
      : MODAL_IDS.COMPLETE_SIGN_UP);
  }, [isSignUpCompleted]);

  const openDetailsModal = useCallback(() => {
    openModal(MODAL_IDS.BONUS_REMINDER_DETAILS);
  }, []);

  const onCountdownComplete = useCallback(() => {
    setIsExpired(true);
  }, []);

  const getPromotedBonusData = () => (
    R.find(R.propEq('bonusPlanId', String(bonusId)), depositBonusesItems || [])
  );

  const getBonuses = () => {
    getAllBonuses();
    getDepositBonuses({
      depositAmount: 1000000,
      paymentMethod: 'IS_CREDITCARD_5',
    });
  };

  const checkIsDisplayWidget = () => (
    dayjs(displayDateAndTime).isValid() ? isPast(displayDateAndTime) : true
  );

  const cleardisplayDateAndTime = () => {
    setdisplayDateAndTime('');
    window.localStorage.removeItem(
      BONUS_REMINDER_DISPLAY_DATE_AND_TIME_LS_KEY,
    );
  };

  const checkIsBonusAvailable = () => {
    const bonusItem = R.find(R.propEq('bonusPlanId', String(bonusId)), allBonusesItems || []);

    if (!bonusItem) {
      return true;
    }

    return [BONUS_STATUS.CANCELED].includes(bonusItem?.status);
  };

  useEffect(() => {
    let timeoutId;

    globalEvents.on(SUCCESS_COMPLETE_PAYMENT_PROCESS, getBonuses);
    getBonuses();

    if (dayjs(displayDateAndTime).isValid() && !isPast(displayDateAndTime)) {
      timeoutId = setTimeout(() => cleardisplayDateAndTime(), dayjs(displayDateAndTime).diff(dayjs()));
    }

    return () => {
      globalEvents.off(SUCCESS_COMPLETE_PAYMENT_PROCESS, getBonuses);
      clearDepositBonuses();
      clearAllBonuses();

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (R.isNil(allBonusesItems || depositBonusesItems)) {
      getBonuses();
    }
  }, [allBonusesItems, depositBonusesItems]);

  const isBonusAvailable = checkIsBonusAvailable();
  const promotedBonusData = getPromotedBonusData();
  const isDisplayWidget = checkIsDisplayWidget();
  const isInProgress = isAllBonusesInProgress || isDepositBonusesInProgress;

  if (!isBonusAvailable || !promotedBonusData || isInProgress) {
    return null;
  }

  return (
    <div
      className={classNames('bonus-reminder-widget position-fixed text-center', {
        'is-collapsed p-0': !isExpired && isCollapsed,
        'pt-1_5 pb-2 px-3': !isExpired && !isCollapsed,
        'is-expired py-1 px-2': isExpired,
        // 'is-sportsbook-page': isSportsbookPage,
        'is-es-locale': locale === 'es',
        'has-navigation': hasNavigation,
        'with-cookies-bar': !isCookiesPolicyAccepted,
        'd-none': !isDisplayWidget,
      })}
      tabIndex="0"
      role="button"
      onClick={isExpired ? () => openDetailsModal(promotedBonusData) : null}
      onKeyPress={isExpired ? () => openDetailsModal(promotedBonusData) : null}
    >

      {isExpired && (
        <>
          <IconGiftWithCircle className="d-block mx-auto mb-1_25" />

          <FormattedTag
            id="bonus-reminder.new"
            className="mb-1 text-white font-weight-bold"
          />
        </>
      )}

      {!isExpired && (
        <>
          {!isCollapsed && (
            <div className="position-relative text-center">
              <IconClose
                className="icon-close position-absolute"
                onClick={toggleCollapse}
              />

              <FormattedTag
                tag="p"
                id="bonus-reminder.title"
                className="mb-1 text-white font-weight-bold px-2"
              />

              <FormattedTag
                tag="p"
                id="bonus-reminder.description"
                className="mb-2 h4 text-white font-weight-bold"
              />
            </div>
          )}

          <div
            tabIndex="0"
            className={classNames('timer-container', { 'mb-2': !isCollapsed })}
            onClick={isCollapsed ? toggleCollapse : null}
            role="button"
            onKeyPress={isCollapsed ? toggleCollapse : null}
          >
            <BonusReminderWidgetTimer expiryDate={expiryDate} onComplete={onCountdownComplete} />
          </div>

          {!isCollapsed && (
            <div className="text-center">
              <FormattedTag
                id="bonus-reminder.deposit-btn"
                tag={Button}
                className="bg-orange-gradient text-white"
                onClick={openDepositModal}
              />
            </div>
          )}
        </>
      )}

      <BonusReminderDetailsModal
        bonusData={promotedBonusData}
        setdisplayDateAndTime={setdisplayDateAndTime}
        getBonuses={getBonuses}
        openDepositModal={openDepositModal}
      />
    </div>
  );
};

BonusReminderWidgetUI.propTypes = {
  allBonuses: PropTypes.shape(),
  getAllBonuses: PropTypes.func.isRequired,
  depositBonuses: PropTypes.shape(),
  getDepositBonuses: PropTypes.func.isRequired,
  clearAllBonuses: PropTypes.func.isRequired,
  clearDepositBonuses: PropTypes.func.isRequired,
  globalEvents: PropTypes.shape().isRequired,
  hasNavigation: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  isCookiesPolicyAccepted: PropTypes.bool.isRequired,
  isSignUpCompleted: PropTypes.bool.isRequired,
  // isSportsbookPage: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  openModalAndCloseOthers: PropTypes.func.isRequired,
  userData: PropTypes.shape(),
};

BonusReminderWidgetUI.defaultProps = {
  allBonuses: {},
  depositBonuses: {},
  userData: {},
};

export const BonusReminderWidget = withBonuses(
  withGlobalEvents(
    withModalActions(
      BonusReminderWidgetUI
    )
  )
);
