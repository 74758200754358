import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { withUser, withLocale } from 'core/hocs';
import {
  addScriptElementToDOM,
  getEnv,
  isEmptyOrNil,
  convertSnakeCaseToCamelCase,
} from 'core/helpers';
import { USER_FIELDS, PRODUCTION, PROJECT_ENV } from 'core/constants';

import { IconLiveChat } from 'components/icons/icon-live-chat/icon-live-chat';

import { SPORTSBOOK_POST_MESSAGE_TYPES } from '../../../constants';

import './live-chat-inc-chat.scss';

export const openLiveChatIncChatWidget = () => {
  if (window.LC_API && window.LC_API.open_chat_window) {
    window.LC_API.open_chat_window();
  }
};

const apiCallbacksNames = [
  'on_before_load',
  'on_after_load',
  'on_chat_window_opened',
  'on_chat_window_minimized',
  'on_chat_window_hidden',
  'on_chat_state_changed',
  'on_chat_started',
  'on_chat_ended',
  'on_message',
  'on_ticket_created',
  'on_prechat_survey_submitted',
  'on_postchat_survey_submitted',
  'on_rating_submitted',
  'on_rating_comment_submitted',
];
const projectEnv = getEnv(PROJECT_ENV);

const getUserUrl = (id) => {
  const domainByEnv = {
    [PRODUCTION]: 'sgp'
  }[projectEnv] || 'stg-sgp';

  return `https://${domainByEnv}.gmntc.com/core/app/core/players/${id}/detail`;
};

class LiveChatIncChatUI extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    group: PropTypes.shape(),
    isButtonHidden: PropTypes.bool,
    userData: PropTypes.shape(),
    additionalParams: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    })),
    chatBetweenGroups: PropTypes.bool,
    onChatScriptLoaded: PropTypes.func,
    onChatScriptLoadingError: PropTypes.func,
    onBeforeLoad: PropTypes.func,
    onAfterLoad: PropTypes.func,
    onChatWindowOpened: PropTypes.func,
    onChatWindowMinimized: PropTypes.func,
    onChatWindowHidden: PropTypes.func,
    onChatStateChanged: PropTypes.func,
    onChatStarted: PropTypes.func,
    onChatEnded: PropTypes.func,
    onMessage: PropTypes.func,
    onTicketCreated: PropTypes.func,
    onPrechatSurveySubmitted: PropTypes.func,
    onPostchatSurveySubmitted: PropTypes.func,
    onRatingSubmitted: PropTypes.func,
    onRatingCommentSubmitted: PropTypes.func,
    isUserLoggedIn: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    /* eslint-enable react/no-unused-prop-types */
  };

  static defaultProps = {
    isButtonHidden: false,
    group: 0,
    chatBetweenGroups: false,
    additionalParams: null,
    userData: {},
    onChatScriptLoaded: () => {},
    onChatScriptLoadingError: () => {},
    onBeforeLoad: () => {},
    onAfterLoad: () => {},
    onChatWindowOpened: () => {},
    onChatWindowMinimized: () => {},
    onChatWindowHidden: () => {},
    onChatStateChanged: () => {},
    onChatStarted: () => {},
    onChatEnded: () => {},
    onMessage: () => {},
    onTicketCreated: () => {},
    onPrechatSurveySubmitted: () => {},
    onPostchatSurveySubmitted: () => {},
    onRatingSubmitted: () => {},
    onRatingCommentSubmitted: () => {},
  };

  componentDidMount() {
    this.loadChatApi();
    window.addEventListener('message', this.onIframeMessage);
  }

  componentDidUpdate(prevProps) {
    const { userData, locale } = this.props;
    const { userData: prevUserData } = prevProps;

    if (!R.isEmpty(userData) && R.isEmpty(prevUserData) && window.LC_API) {
      if (window.LC_API.set_visitor_name) {
        window.LC_API.set_visitor_name(userData.firstName);
      }

      if (window.LC_API.set_visitor_email) {
        window.LC_API.set_visitor_email(userData.email);
      }

      if (window.LC_API.set_custom_variables) {
        window.LC_API.set_custom_variables([
          { name: 'id', value: userData.partyId },
          { name: 'link', value: getUserUrl(userData.partyId) },
          { name: 'level', value: userData.vipStatus },
          { name: 'project', value: 'Crasher' },
          { name: 'language', value: locale },
        ]);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onIframeMessage);
  }

  onIframeMessage = ({ data }) => {
    const { type } = data || {};

    if (type === SPORTSBOOK_POST_MESSAGE_TYPES.OPEN_CHAT) {
      this.onClick();
    }
  };

  chatScriptIsLoadedWithSuccess = () => {
    if (window.LC_API) {
      this.setApiCallbacks();
      this.props.onChatScriptLoaded(window.LC_API);
    }
  };

  chatScriptIsLoadedWithError = () => {
    this.props.onChatScriptLoadingError();
  };

  loadChatApi = () => {
    const licenseKey = getEnv('LIVECHATINC_API_LICENSE_KEY');

    if (isEmptyOrNil(licenseKey)) {
      throw new Error('`LIVECHATINC_API_LICENSE_KEY` env\'s key is REQUIRED for adding the LiveChatInc widget');
    }

    const {
      group,
      chatBetweenGroups,
      userData,
      isUserLoggedIn,
      locale,
    } = this.props;

    const groupAuthorizationKey = isUserLoggedIn ? 'authenticated' : 'unauthenticated';

    if (!window.LC_API) {
      window.__lc = window.__lc || {};
      window.__lc.license = licenseKey;
      window.__lc.group = group[groupAuthorizationKey];
      window.__lc.chat_between_groups = chatBetweenGroups;
      let params = [
        { name: 'project', value: 'Crasher' },
        { name: 'language', value: locale },
      ];

      if (!R.isEmpty(userData)) {
        params = [
          ...params,
          { name: 'id', value: userData.partyId },
          { name: 'link', value: getUserUrl(userData.partyId) },
          { name: 'level', value: userData.vipStatus },
        ];

        window.__lc.visitor = {
          name: userData.firstName,
          email: userData.email,
        };
      }

      window.__lc.params = params;

      addScriptElementToDOM({
        src: '//cdn.livechatinc.com/tracking.js',
        async: true,
        attributes: {
          'data-script-livechatinc': '',
          // Disables Cloudflare Rocket Loader script
          'data-cfasync': 'false',
        },
        onLoadEventListener: this.chatScriptIsLoadedWithSuccess,
        onErrorEventListener: this.chatScriptIsLoadedWithError,
      });
    }
  };

  setApiCallbacks = () => {
    apiCallbacksNames.forEach((apiCallbacksName) => {
      const propCallbackName = convertSnakeCaseToCamelCase(apiCallbacksName);
      const propCallback = R.propOr(null, propCallbackName, this.props);

      if (!isEmptyOrNil(propCallback) && typeof propCallback === 'function') {
        window.LC_API[apiCallbacksName] = propCallback;
      }
    });
  };

  onClick = () => {
    openLiveChatIncChatWidget();
  };

  render() {
    const { isButtonHidden, isUserLoggedIn } = this.props;

    if (isButtonHidden) {
      return null;
    }

    return (
      <div
        className={classNames('custom-chat-button custom-chat-button-livechat d-flex justify-content-center align-items-center position-fixed', { 'not-authenticated': !isUserLoggedIn })}
        role="button"
        tabIndex="0"
        onClick={this.onClick}
        onKeyPress={this.onClick}
      >
        <IconLiveChat />
      </div>
    );
  }
}

export const LiveChatIncChat = withLocale(
  withUser(LiveChatIncChatUI, [USER_FIELDS.USER_DATA, USER_FIELDS.IS_USER_LOGGED_IN])
);
