import React from 'react';
import PropTypes from 'prop-types';

export const IconDepositLegal = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <g id="verified_user_24dp_5F6368_FILL1_wght400_GRAD0_opsz24 1">
      <path id="Vector" d="M15.2667 16.9334L13.4 15.0667C13.1333 14.8 12.8222 14.6667 12.4667 14.6667C12.1111 14.6667 11.8 14.8 11.5333 15.0667C11.2667 15.3334 11.1333 15.65 11.1333 16.0167C11.1333 16.3834 11.2667 16.7 11.5333 16.9667L14.3333 19.8C14.6 20.0667 14.9111 20.2 15.2667 20.2C15.6222 20.2 15.9333 20.0667 16.2 19.8L21.8667 14.1334C22.1333 13.8667 22.2667 13.55 22.2667 13.1834C22.2667 12.8167 22.1333 12.5 21.8667 12.2334C21.6 11.9667 21.2833 11.8334 20.9167 11.8334C20.55 11.8334 20.2333 11.9667 19.9667 12.2334L15.2667 16.9334ZM16.6667 29.2C16.5111 29.2 16.3667 29.1889 16.2333 29.1667C16.1 29.1445 15.9667 29.1111 15.8333 29.0667C12.8333 28.0667 10.4444 26.2167 8.66667 23.5167C6.88889 20.8167 6 17.9111 6 14.8V8.50003C6 7.94448 6.16111 7.44448 6.48333 7.00003C6.80556 6.55559 7.22222 6.23337 7.73333 6.03337L15.7333 3.03337C16.0444 2.92225 16.3556 2.8667 16.6667 2.8667C16.9778 2.8667 17.2889 2.92225 17.6 3.03337L25.6 6.03337C26.1111 6.23337 26.5278 6.55559 26.85 7.00003C27.1722 7.44448 27.3333 7.94448 27.3333 8.50003V14.8C27.3333 17.9111 26.4444 20.8167 24.6667 23.5167C22.8889 26.2167 20.5 28.0667 17.5 29.0667C17.3667 29.1111 17.2333 29.1445 17.1 29.1667C16.9667 29.1889 16.8222 29.2 16.6667 29.2Z" fill="#121D41" fillOpacity="0.5" />
    </g>
  </svg>
);

IconDepositLegal.propTypes = {
  className: PropTypes.string,
};

IconDepositLegal.defaultProps = {
  className: '',
};
