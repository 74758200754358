import * as R from 'ramda';

export const getLastSuccessfulPaymentMethod = (completedDepositTransactions) => {
  let sortedCompletedTransactions;
  let lastSuccessfulMethod = null;

  if (completedDepositTransactions) {
    sortedCompletedTransactions = R.sort(R.descend(R.prop('date')), completedDepositTransactions);
    lastSuccessfulMethod = sortedCompletedTransactions.length
      ? sortedCompletedTransactions[0].paymentMethod : null;
  }

  return lastSuccessfulMethod;
};
