import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormProcessDeposit } from 'components/forms/form-process-deposit/form-process-deposit';

import { FormattedTag } from 'core/components';
import { IconDepositLegal } from 'components/icons/icon-deposit-security/icon-deposit-legal';
import { IconDepositSupport } from 'components/icons/icon-deposit-security/icon-deposit-support';
import { IconDepositSecurity } from 'components/icons/icon-deposit-security/icon-deposit-security';

import './deposit-new.scss';

const SECURITY_ITEMS = [
  {
    Icon: IconDepositLegal,
    key: 'deposit.security.legal',
  },
  {
    Icon: IconDepositSupport,
    key: 'deposit.security.support',
  },
  {
    Icon: IconDepositSecurity,
    key: 'deposit.security.security',
  }
];

export const DepositNew = ({
  className,
  handlerPaymentChangeForMobile,
  handlerPaymentChangeForDesktop,
  activeDepositMethod,
  handlerStep,
  updateModalAlignment,
  isModal,
}) =>
  (
    <div
      className={classNames('deposit-new d-flex flex-column justify-content-start align-items-center pb-0 pb-sm-5 rounded', { 'pt-sm-0': isModal, 'pt-sm-2_75': !isModal }, className)}
    >
      <FormProcessDeposit
        activeDepositMethod={activeDepositMethod}
        updateModalAlignment={updateModalAlignment}
        handlerPaymentChangeForMobile={handlerPaymentChangeForMobile}
        handlerPaymentChangeForDesktop={handlerPaymentChangeForDesktop}
        handlerStep={handlerStep}
        isModal={isModal}
      />
      <div className="d-flex justify-content-around align-items-center mt-1_5 mb-2">
        {SECURITY_ITEMS.map(({
          Icon,
          key,
        }) => (
          <div
            key={key}
            className="text-center position-relative h-100 mr-2"
          >
            <Icon className={classNames({ 'security-icon-white': !isModal })} />
            <FormattedTag
              id={key}
              className="category-name text-capitalize d-block font-weight-semi-bold pt-1_25"
            />
          </div>
        ))}
      </div>
      <FormattedTag
        id="deposit.security.license-text"
        className="mx-3"
      />
    </div>
  );

DepositNew.propTypes = {
  className: PropTypes.string,
  activeDepositMethod: PropTypes.string.isRequired,
  handlerPaymentChangeForMobile: PropTypes.func.isRequired,
  handlerPaymentChangeForDesktop: PropTypes.func.isRequired,
  handlerStep: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  updateModalAlignment: PropTypes.func,
};

DepositNew.defaultProps = {
  className: '',
  updateModalAlignment: null,
};
