import React from 'react';
import PropTypes from 'prop-types';
import { withModalData } from 'core/hocs';
import { PAYMENT_TYPES } from 'core/constants';
import { ModalWindow } from 'components/modal-window/modal-window';
import { FormCompleteSignUp } from 'components/forms/form-complete-sign-up/form-complete-sign-up';
import { MODAL_IDS } from '../../../constants';

const CompleteSignUpModalUI = ({ currentModal }) => {
  const isLongForm = currentModal?.data?.isLongForm ?? false;

  return (
    <ModalWindow
      id={MODAL_IDS.COMPLETE_SIGN_UP}
      coreProps={{ id: MODAL_IDS.COMPLETE_SIGN_UP }}
      contentClassName="border-0 complete-profile-modal bg-white"
      isModalCentered
      fade
    >
      {isLongForm
        ? <FormCompleteSignUp isLongForm coreProps={{ action: PAYMENT_TYPES.WITHDRAWAL }} />
        : <FormCompleteSignUp coreProps={{ action: PAYMENT_TYPES.DEPOSIT }} />}
    </ModalWindow>
  );
};

CompleteSignUpModalUI.propTypes = {
  currentModal: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.any
  }).isRequired,
};

export const CompleteSignUpModal = withModalData(CompleteSignUpModalUI);
