import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';

import { withModalActions } from 'core/hocs';
import { FormattedTag, RouteLink } from 'core/components';

import { menuItems } from 'components/header/menu-items';

import {
  MODAL_IDS,
  MODAL_OPEN_CLASS,
  PAGE_NAMES,
  SIDE_MODAL_IDS,
} from '../../../constants';
import { IconProfile } from '../../icons/icon-profile/icon-profile';
import { IconGiftOutline } from '../../icons/icon-gift-outline/icon-gift-outline';
import { IconWallet } from '../../icons/icon-wallet/icon-wallet';
import { IconTransactions } from '../../icons/icon-transactions/icon-transactions';
import { IconSupport } from '../../icons/icon-support/icon-support';

import { TournamentsDropdownMenuItem } from './tournaments-dropdown-menu-item';

import './sidebar-menu.scss';

const {
  PROFILE,
  BANKING_HISTORY,
  WALLET,
  GIFTS,
  DEPOSIT,
  WITHDRAWAL,
  PLAY_HISTORY,
  VERIFICATION,
} = PAGE_NAMES;

const sidebarGridItems = [
  {
    id: 'sidebar.navigation.my-account',
    to: '/profile',
    Icon: IconProfile,
    pages: [PROFILE],
    classNameLink: 'mb-1_75',
  },
  {
    id: 'sidebar.navigation.gifts',
    to: '/profile/gifts',
    Icon: IconGiftOutline,
    pages: [GIFTS],
    classNameLink: 'mb-1_75',
  },
  {
    id: 'sidebar.navigation.wallet',
    to: '/profile/deposit',
    Icon: IconWallet,
    pages: [WALLET],
  },
  {
    id: 'sidebar.navigation.transactions',
    to: '/profile/banking-history',
    Icon: IconTransactions,
    pages: [BANKING_HISTORY],
  },
];

export const profileMenuItems = [
  {
    id: 'menu.profile',
    tab: '',
  },
  {
    id: 'menu.bonuses',
    tab: GIFTS,
  },
  {
    id: 'menu.deposit',
    tab: DEPOSIT,
  },
  {
    id: 'menu.withdrawal',
    tab: WITHDRAWAL,
  },
  {
    id: 'profile-menu.history',
    tab: PLAY_HISTORY,
  },
  {
    id: 'menu.transactions',
    tab: BANKING_HISTORY,
  },
  {
    id: 'menu.verification',
    tab: VERIFICATION,
  },
];

class SidebarMenuUI extends Component {
  closeProfileModal = () => {
    const { closeModal } = this.props;

    closeModal(SIDE_MODAL_IDS.SIDE_MODAL_BAR);
    closeModal(MODAL_IDS.PROFILE);

    setTimeout(() => {
      document.body.classList.remove(MODAL_OPEN_CLASS);
    }, 700);
  };

  openModalAction = (e) => {
    const { openModal, closeModal } = this.props;
    e.preventDefault();

    closeModal(SIDE_MODAL_IDS.SIDE_MODAL_BAR);
    openModal(MODAL_IDS.SUPPORT);
  };

  renderSidebarGridItems = () => {
    const {
      pageName,
    } = this.props;

    return sidebarGridItems.map(({
      id, to = '', pages, Icon, classNameLink,
    }) => {
      const isActive = pages.includes(pageName) || false;

      return (
        <RouteLink
          key={id}
          onClick={this.closeProfileModal}
          to={to}
          className={classNames('menu-item d-flex flex-column align-items-center rounded font-weight-bold pt-2 pb-1_25 pb-md-1 justify-content-between', classNameLink, {
            'active border border-primary': isActive,
          })}
        >
          {Icon && <Icon className="menu-icon" />}
          <FormattedTag id={id} />
        </RouteLink>
      );
    });
  };

  renderMenuItemsLinks = () => {
    const {
      pageName,
      params,
      location: {
        search,
      },
    } = this.props;

    const queryParams = R.last(search.split('?'));
    const menuItemsLinks = menuItems.filter(({ isDropdown }) => !isDropdown);

    return menuItemsLinks.map(({
      id,
      to,
      pages,
      Icon,
      IconMobile,
      classNameSidebar,
    }) => (
      <RouteLink
        key={id}
        to={to}
        onClick={this.closeProfileModal}
        className={classNames('menu-item py-1_5 d-flex align-items-center font-weight-semi-bold', classNameSidebar, {
          active: queryParams ? queryParams === R.last(to.split('?'))
            : !params && pages.includes(pageName) || pages.includes(`${pageName}/${params}`),
        })}
      >
        <span className="menu-icon-container mr-2">
          {IconMobile ? <IconMobile className="menu-icon" /> : <Icon className="menu-icon" />}
        </span>
        <FormattedTag
          id={id}
          className="text-white ml-2"
        />
      </RouteLink>
    ));
  };

  renderMenuItemsDropdown = () => {
    const menuItemsDropdown = menuItems.filter(({ isDropdown }) => isDropdown);
    const tournamentsDropdownMenuItem = menuItemsDropdown.find(({ pages }) => pages.includes('tournaments'));

    if (tournamentsDropdownMenuItem) {
      return (
        <TournamentsDropdownMenuItem
          Icon={tournamentsDropdownMenuItem.Icon}
          translationKey={tournamentsDropdownMenuItem.id}
          onClick={this.closeProfileModal}
        />
      );
    }

    return null;
  };

  render() {
    const {
      isGridView,
    } = this.props;

    return (
      <div className={classNames('sidebar-menu d-flex flex-wrap', {
        'grid-view flex-wrap justify-content-between mt-2_25 mb-2_5': isGridView,
        'flex-column pt-1 pb-1_5 px-1_75': !isGridView,
      })}
      >
        {isGridView ? (
          <>
            {this.renderSidebarGridItems()}
          </>
        ) : (
          <>
            {this.renderMenuItemsLinks()}
            {this.renderMenuItemsDropdown()}
            <RouteLink
              onClick={this.openModalAction}
              to="/"
              className="menu-item py-1_5 d-flex align-items-center font-weight-semi-bold"
            >
              <span className="menu-icon-container mx-1_5">
                <IconSupport className="menu-icon" />
              </span>
              <FormattedTag id="footer.link.support" className="text-white ml-1_5" />
            </RouteLink>
          </>
        )}
      </div>
    );
  }
}

SidebarMenuUI.propTypes = {
  pageName: PropTypes.string,
  params: PropTypes.string,
  isGridView: PropTypes.bool,
  location: PropTypes.shape().isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

SidebarMenuUI.defaultProps = {
  params: null,
  pageName: '',
  isGridView: false,
};

export const SidebarMenu = withModalActions(withRouter(SidebarMenuUI));
