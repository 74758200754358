import React from 'react';
import PropTypes from 'prop-types';

export const IconDepositSecurity = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <g id="security_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24 1">
      <path id="Vector" d="M16.3337 26.5334C18.4892 25.8667 20.2892 24.55 21.7337 22.5834C23.1781 20.6167 24.0225 18.4223 24.267 16H16.3337V5.50003L8.33366 8.50003V15.4C8.33366 15.5556 8.35588 15.7556 8.40033 16H16.3337V26.5334ZM16.3337 29.2C16.1781 29.2 16.0337 29.1889 15.9003 29.1667C15.767 29.1445 15.6337 29.1111 15.5003 29.0667C12.5003 28.0667 10.1114 26.2167 8.33366 23.5167C6.55588 20.8167 5.66699 17.9111 5.66699 14.8V8.50003C5.66699 7.94448 5.8281 7.44448 6.15033 7.00003C6.47255 6.55559 6.88921 6.23337 7.40033 6.03337L15.4003 3.03337C15.7114 2.92225 16.0225 2.8667 16.3337 2.8667C16.6448 2.8667 16.9559 2.92225 17.267 3.03337L25.267 6.03337C25.7781 6.23337 26.1948 6.55559 26.517 7.00003C26.8392 7.44448 27.0003 7.94448 27.0003 8.50003V14.8C27.0003 17.9111 26.1114 20.8167 24.3337 23.5167C22.5559 26.2167 20.167 28.0667 17.167 29.0667C17.0337 29.1111 16.9003 29.1445 16.767 29.1667C16.6337 29.1889 16.4892 29.2 16.3337 29.2Z" fill="#121D41" fillOpacity="0.5" />
    </g>
  </svg>
);

IconDepositSecurity.propTypes = {
  className: PropTypes.string,
};

IconDepositSecurity.defaultProps = {
  className: '',
};
