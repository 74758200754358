import { safeJsonParse } from 'core/helpers';

export const parseJson = (data, errorMessage = '', fallback = {}) => {
  try {
    return safeJsonParse(data);
  } catch (e) {
    console.log(errorMessage, e);

    return fallback;
  }
};
