import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'modules/ramda';
import { encodeSVG, getPaymentMethodIcon, isEmptyOrNil } from 'core/helpers';

const FooterPaymentItemUI = ({ paymentMethod, onClick }) => {
  const icon = getPaymentMethodIcon(paymentMethod) || null;
  const iconSrc = !R.isNil(icon) && icon.includes('<svg') ? `data:image/svg+xml;utf8,${encodeSVG(icon)}` : icon;

  if (isEmptyOrNil(iconSrc)) {
    return null;
  }

  return (
    <div onClick={onClick}>
      <img
        key={paymentMethod}
        src={iconSrc}
        className="d-inline footer-payment-icon text-center"
        alt=""
      />
    </div>
  );
};

FooterPaymentItemUI.propTypes = {
  paymentMethod: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const FooterPaymentItem = FooterPaymentItemUI;
