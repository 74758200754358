/* eslint-disable global-require */
// `ISO 3166-1 alpha-2` code standard (in lower-cased format) should be used for locales and countries
module.exports = {
  DEFAULT_CURRENCY: 'MXN',

  DEFAULT_COUNTRY: 'mx',

  DEFAULT_LOCALE: 'es',
  SEARCH_BOT_LOCALE: 'es',
  TARGET_USER_BROWSER_PREFERRED_LOCALES_LIST: ['es'],

  BRAND_LOCALES: {
    EN: 'en',
    ES: 'es',
  },

  DEFAULT_THEME: 'light',

  /**
   * {[iso2Code]: [currencyCode]}
   */
  DEFAULT_CURRENCIES_BY_COUNTRY: {
    // SE: 'SEK',
  },

  /**
   * {[iso2Code]: [locale]}
   */
  TARGET_USER_LOCALE_BY_COUNTRY_IP: {
    MX: 'es',
  },

  PROCESS_DEPOSIT: {
    // If "NONE" is passed then no bonus will be created.
    // If this is not passed at all or "DEFAULT" is passed then the most appropriate bonus will be selected.
    DEFAULT_BONUS_OPTION: 'NONE',
  },

  FORMS: {
    FIELDS: require('./forms'),
  },

  COMPLETE_SIGN_UP_CHECK_REQUIRED_FIELDS: [
    'firstName',
    'lastName'
  ],

  COMPLETE_SIGN_UP_WITHDRAWAL_CHECK_REQUIRED_FIELDS: [
    'year',
    'month',
    'day',
    'gender',
    'docNumber',
    'city',
    'postalCode',
    'address',
  ],

  // TODO: maybe remove to another place
  VERIFICATION: {
    MAX_SIZE: 10 * 1024 * 1024, // 10mb
    ACCEPT: 'image/jpeg, image/png',
  },

  MIN_POINTS_TO_EXCHANGE: 100,

  // Values for CPs to be multiple of. If not set the default is 1.
  MIN_STEP_TO_EXCHANGE_PER_CURRENCY: {
    MXN: 100,
    USD: 100,
  },

  IFRAME_PAYMENT_METHODS: ['IS_V2_BANKTRANSFER18', 'IS_V2_CREDITCARD35', 'IS_V2_OXXO', 'IS_V2_OXXO2', 'IS_V2_SPEI', 'IS_V2_CODI', 'IS_V2_CODI2', 'IS_V2_SPEI2', 'IS_V2_7ELEVEN', 'IS_V2_7ELEVEN2', 'IS_V2_ALSUPER', 'IS_V2_BANCOMER', 'IS_V2_BANORTE', 'IS_V2_BANORTE2', 'IS_V2_BENAVIDES', 'IS_V2_BODEGAAURRERA', 'IS_V2_BODEGAAURRERA2', 'IS_V2_CALIMAX2', 'IS_V2_CASHMX', 'IS_V2_CIRCULOK', 'IS_V2_CIRCULOK2', 'IS_V2_COMERCIALMEXICANA', 'IS_V2_DELAHORRO', 'IS_V2_ELASTURIANO', 'IS_V2_EXTRA', 'IS_V2_EXTRA2', 'IS_V2_ROMA', 'IS_V2_FARMROMA', 'IS_V2_KIOSKO', 'IS_V2_LAMASBARATA', 'IS_V2_PAYCASH', 'IS_V2_PAYNET', 'IS_V2_PAYNET2', 'IS_V2_SAMSCLUB', 'IS_V2_SAMSCLUB2', 'IS_V2_SORIANA', 'IS_V2_SORIANA2', 'IS_V2_STAMARIA', 'IS_V2_SUPERAMA', 'IS_V2_SUPERAMA2', 'IS_V2_TELECOMM', 'IS_V2_TELECOMM2', 'IS_V2_WALDOS', 'IS_V2_WALMART', 'IS_V2_WALMART2', 'IS_V2_WALMART3', 'IS_V2_SPEI3'],

  IS_DISPLAY_REMAINING_LOGIN_ATTEMPTS: true,
};
/* eslint-enable global-require */
