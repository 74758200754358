import React from 'react';
import PropTypes from 'prop-types';
import { FormattedTag } from 'core/components';
import { CustomCountdown } from 'components/custom-countdown/custom-countdown';
import { padZeroToNumber } from '../../helpers/numbers';

import './bonus-reminder-widget-timer.scss';

export const BonusReminderWidgetTimer = ({ expiryDate, onComplete }) => (
  <CustomCountdown date={expiryDate} onComplete={onComplete}>
    {({ formatted: { hours, minutes } }) => (
      <div className="bonus-reminder-widget-timer d-flex mx-auto p-1 font-weight-bold">
        <div className="bonus-reminder-widget-timer-item minutes py-1 bg-white text-blue text-center">
          <span className="d-block mb-1">
            {hours}
          </span>
          <FormattedTag
            id="bonus-reminder.timer.hours"
            className="d-block caption"
          />
        </div>
        <div className="separator mx-0_75 py-2_75">
          <span className="separator-item d-block mb-0_5 bg-white" />
          <span className="separator-item d-block bg-white" />
        </div>
        <div className="bonus-reminder-widget-timer-item seconds py-1 bg-white text-blue text-center">
          <span className="d-block mb-1">
            {padZeroToNumber(+minutes + 1)}
          </span>
          <FormattedTag
            id="bonus-reminder.timer.minutes"
            className="d-block caption"
          />
        </div>
      </div>
    )}
  </CustomCountdown>
);

BonusReminderWidgetTimer.propTypes = {
  expiryDate: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
};
