import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withCompletedTransactions } from 'core/hocs';
import { PAYMENT_METHODS_CATEGORIES } from 'core/constants';
import { PaymentsMenu } from 'components/payments-menu/payments-menu';

import './deposit-options.scss';

export const DepositOptionsUI = ({
  className,
  items,
  currency,
  handleDepositOptions,
  isModal,
  lastSuccessfulMethod,
}) => {
  const allItems = [...(items[PAYMENT_METHODS_CATEGORIES.OTHER] || []),
    ...(items[PAYMENT_METHODS_CATEGORIES.RECENTLY] || [])];
  const otherItems = R.reject(R.propEq('paymentMethod', lastSuccessfulMethod))(allItems || {});
  const recentlyItems = R.filter(R.propEq('paymentMethod', lastSuccessfulMethod))(allItems || {});

  return (
    <div className={classNames('deposit-options position-relative d-flex flex-column', className)}>
      <PaymentsMenu
        otherItems={otherItems}
        recentlyItems={recentlyItems}
        handleSelectedCard={handleDepositOptions}
        currency={currency}
        isModal={isModal}
      />
    </div>
  );
};

DepositOptionsUI.propTypes = {
  className: PropTypes.string,
  handleDepositOptions: PropTypes.func.isRequired,
  items: PropTypes.shape({
    recently: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        paymentMethod: PropTypes.string,
        minimumDeposit: PropTypes.number,
        feeRate: PropTypes.number,
        conversionRequired: PropTypes.bool,
        maximumDeposit: PropTypes.number,
        popupRequired: PropTypes.bool,
        method: PropTypes.string,
      }),
    ),
    other: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  currency: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  lastSuccessfulMethod: PropTypes.string,
};

DepositOptionsUI.defaultProps = {
  className: '',
  isModal: false,
  lastSuccessfulMethod: null,
};

export const DepositOptions = withCompletedTransactions(DepositOptionsUI);
