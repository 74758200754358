import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RouteLink, FormattedTag } from 'core/components';
import { withUserAgent } from 'core/hocs';
import { withSafariSearchBar } from 'hocs/with-safari-search-bar';
import { IconSlotMachine } from 'components/icons/icons-navigation/icon-slot-machine';
import { IconLiveCasino } from 'components/icons/icon-live-casino/icon-live-casino';
import { IconSports } from 'components/icons/icon-sports/icon-sports';
import { IconPromos } from 'components/icons/icon-promos/icon-promos';
import { DepositButtonMobile } from 'components/deposit-button-mobile/deposit-button-mobile';
import { PAGE_NAMES } from '../../constants';

import './casino-navigation.scss';

const {
  GAMES, PROMOS, TOURNAMENT, LOYALTY, SPORTSBOOK
} = PAGE_NAMES;

const menuItems = [
  {
    id: 'profile-menu.casino',
    to: '/games',
    Icon: IconSlotMachine,
    pages: [GAMES, `${GAMES}/all`, `${GAMES}/slot`, `${GAMES}/roulette`, `${GAMES}/card`, `${GAMES}/sport`],
  },
  {
    id: 'profile-menu.live-casino',
    to: '/games/live',
    Icon: IconLiveCasino,
    pages: [`${GAMES}/live`],
  },
  {
    type: 'button',
    Component: DepositButtonMobile
  },
  {
    id: 'profile-menu.sportsbook',
    to: '/sportsbook',
    Icon: IconSports,
    pages: [SPORTSBOOK],
  },
  {
    id: 'profile-menu.promos',
    to: '/promos',
    Icon: IconPromos,
    pages: [PROMOS, TOURNAMENT, LOYALTY],
  },
];

export const CasinoNavigationUI = ({
  pageName, navBarHeight, params, isPwa
}) => (
  <div
    className="casino-navigation bg-blue-gradient pwa-blur d-md-none"
    style={{
      ...!!navBarHeight && ({
        // default min-height = $casino-navigation-height (60px) + $casino-navigation-ios-gap (2px)
        minHeight: navBarHeight + 62, paddingBottom: navBarHeight
      })
    }}
  >
    {menuItems.map(({
      id, to, pages, Icon, type, Component
    }) => {
      const isActive = params ? pages?.includes(`${pageName}/${params}`) : pages?.includes(pageName);

      if (type === 'button') {
        return <Component isPwa={!!navBarHeight || isPwa} />;
      }

      return (
        <RouteLink
          key={id}
          to={to}
          className="d-flex flex-column flex-sm-row justify-content-center align-items-center icon-parent py-0_5 text-decoration-none"
        >
          <Icon className={classNames('casino-navigation-menu-icon mb-0_5 mb-sm-0', { active: isActive })} />
          <FormattedTag
            id={id}
            className={classNames('casino-navigation-menu-title ml-sm-1_5 font-weight-semi-bold', {
              'text-white': isActive,
              'text-gray-20': !isActive,
            })}
          />
        </RouteLink>
      );
    })}
  </div>
);

CasinoNavigationUI.propTypes = {
  pageName: PropTypes.string,
  navBarHeight: PropTypes.number.isRequired,
  params: PropTypes.string,
  isPwa: PropTypes.bool.isRequired,
};

CasinoNavigationUI.defaultProps = {
  pageName: null,
  params: null
};

export const CasinoNavigation = withUserAgent(withSafariSearchBar(CasinoNavigationUI));
