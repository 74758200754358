/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Button from 'reactstrap/lib/Button';
import { withModalActions, withModalData } from 'core/hocs';
import { FormattedTag } from 'core/components';

import { ModalWindow } from 'components/modal-window/modal-window';
import { CustomCountdown } from 'components/custom-countdown/custom-countdown';
import { BONUS_REMINDER_DISPLAY_DATE_AND_TIME_LS_KEY, MODAL_IDS } from '../../../constants';

import './bonus-reminder-details-modal.scss';

const countdownItemCommonStyles = 'bonus-reminder-details-modal-countdown-item d-inline-block text-center';

const CountdownSeparatorEl = () => (
  <span className="separator d-inline-block mx-0_75">
    <span className="separator-item d-block mb-0_75 bg-green-gradient" />
    <span className="separator-item d-block mb-0_5 bg-green-gradient" />
  </span>
);

const BonusReminderDetailsModalUI = ({
  bonusData,
  closeModal,
  openDepositModal,
  setdisplayDateAndTime,
  getBonuses,
}) => {
  const {
    endDate,
  } = bonusData || {};

  const handleHide = useCallback(() => {
    const displayDateAndTime = dayjs().endOf('day').toISOString();

    closeModal(MODAL_IDS.BONUS_REMINDER_DETAILS);

    setdisplayDateAndTime(displayDateAndTime);
    window.localStorage.setItem(
      BONUS_REMINDER_DISPLAY_DATE_AND_TIME_LS_KEY,
      displayDateAndTime,
    );
  }, []);

  const onCountdownComplete = useCallback(() => {
    if (getBonuses) {
      getBonuses();
    }

    closeModal(MODAL_IDS.BONUS_REMINDER_DETAILS);
  }, []);

  return (
    <ModalWindow
      id={MODAL_IDS.BONUS_REMINDER_DETAILS}
      coreProps={{ id: MODAL_IDS.BONUS_REMINDER_DETAILS }}
      className="bonus-reminder-details-modal mb-0 m-sm-auto w-100 text-center"
      bodyClassName="p-2 p-sm-4 bg-white"
      isModalCentered={false}
      fade
    >
      <img
        width="80"
        height="80"
        src="/assets/public/images/rocket.webp"
        alt="rocket"
        className="d-block mx-auto mb-2 rounded bg-light"
      />

      <FormattedTag
        tag="div"
        id="bonus-reminder.modal.title"
        className="h2 mb-2 text-center text-blue-dark font-family-oswald text-uppercase"
      />

      <FormattedTag
        tag="div"
        id="bonus-reminder.modal.description"
        className="mb-2 text-center text-blue-dark font-weight-semi-bold"
        isHtml
      />

      <div className="mb-4 p-2 rounded rounded-lg bg-pale-green-gradient">
        {endDate && (
          <CustomCountdown date={endDate} onComplete={onCountdownComplete}>
            {({ days, formatted: { hours, minutes, seconds } }) => (
              <div className="bonus-reminder-details-modal-countdown font-weight-bold">
                <FormattedTag
                  id="bonus-reminder.modal.countdown.title"
                  className="d-block mb-1 text-blue-dark"
                />

                <div className="bonus-reminder-details-modal-countdown-items p-0_5 bg-green-gradient text-center font-family-oswald">
                  <span className="mr-0_75">
                    <span className={countdownItemCommonStyles}>
                      {days}
                    </span>
                    <FormattedTag
                      id="bonus-reminder.modal.countdown.days"
                    />
                  </span>

                  <span className={countdownItemCommonStyles}>
                    {hours}
                  </span>

                  <CountdownSeparatorEl />

                  <span className={countdownItemCommonStyles}>
                    {minutes}
                  </span>

                  <CountdownSeparatorEl />

                  <span className={countdownItemCommonStyles}>
                    {seconds}
                  </span>
                </div>
              </div>
            )}
          </CustomCountdown>
        )}
      </div>

      <FormattedTag
        tag={Button}
        color="primary"
        className="w-100 mb-1"
        id="bonus-reminder.modal.deposit-btn"
        onClick={openDepositModal}
      />

      <FormattedTag
        tag={Button}
        className="hide-btn w-100 bg-pale-green-gradient text-primary no-effects"
        id="bonus-reminder.modal.hide-btn"
        onClick={handleHide}
      />
    </ModalWindow>
  );
};

BonusReminderDetailsModalUI.propTypes = {
  bonusData: PropTypes.shape(),
  closeModal: PropTypes.func.isRequired,
  openDepositModal: PropTypes.func.isRequired,
  setdisplayDateAndTime: PropTypes.func.isRequired,
  getBonuses: PropTypes.func.isRequired,
};

BonusReminderDetailsModalUI.defaultProps = {
  bonusData: {},
};

export const BonusReminderDetailsModal = withModalActions(
  withModalData(
    BonusReminderDetailsModalUI
  )
);
