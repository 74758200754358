import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withModalActions,
  withCoreComponent,
  withUser,
} from 'core/hocs';
import { FormattedTag, Payments as PaymentsCore } from 'core/components';
import { MODAL_IDS } from 'src/constants';
import { USER_FIELDS } from 'core/constants';
import { isEmptyOrNil } from 'core/helpers';
import { FooterPaymentItem } from './footer-payment-item/footer-payment-item';

const FooterPaymentItemsUI = ({
  items: {
    depositMethods,
  },
  setActiveDepositMethod,
  isInProgress,
  isUserLoggedIn,
  isUserRegistered,
  isSignUpCompleted,
  openModalAndCloseOthers
}) => {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (!isInProgress && !isEmptyOrNil(depositMethods)) {
      setPayments(depositMethods);
    }
  }, [isInProgress, depositMethods]);

  const openDepositFlowModal = () => {
    let modalId = MODAL_IDS.SIGN_UP;

    if (isUserLoggedIn && !isSignUpCompleted) {
      modalId = MODAL_IDS.COMPLETE_SIGN_UP;
    } else if (isUserRegistered) {
      modalId = MODAL_IDS.SIGN_IN;
    }

    openModalAndCloseOthers(modalId);
  };

  const handlePaymentClick = paymentMethod => () => {
    if (isUserLoggedIn && isSignUpCompleted) {
      setActiveDepositMethod(paymentMethod);
      openModalAndCloseOthers(MODAL_IDS.DEPOSIT);
    } else {
      openDepositFlowModal();
    }
  };

  if (isEmptyOrNil(payments)) {
    return null;
  }

  return (
    <div
      className="d-flex flex-column align-items-center border-bottom border-gray-10-20 pt-3 pt-sm-4 pb-3 pb-sm-8 pb-lg-4"
    >
      <FormattedTag
        id="payments.title"
        className="text-base text-white font-weight-semi-bold"
      />
      <div
        className="footer-payments d-flex flex-wrap justify-content-center align-items-baseline pt-2"
      >
        {payments.map(({ paymentMethod }) => (
          <FooterPaymentItem
            paymentMethod={paymentMethod}
            onClick={handlePaymentClick(paymentMethod)}
          />
        ))}
      </div>
    </div>
  );
};

FooterPaymentItemsUI.propTypes = {
  items: PropTypes.shape({
    depositMethods: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      paymentMethod: PropTypes.string,
      minimumDeposit: PropTypes.number,
      feeRate: PropTypes.number,
      conversionRequired: PropTypes.bool,
      maximumDeposit: PropTypes.number,
      popupRequired: PropTypes.bool,
      method: PropTypes.string,
    })),
    withdrawalMethods: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      paymentMethod: PropTypes.string,
      minimumWithdrawal: PropTypes.number,
      maximumWithdrawal: PropTypes.number,
      conversionRequired: PropTypes.bool,
    })),
  }),
  setActiveDepositMethod: PropTypes.func.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool,
  isUserRegistered: PropTypes.bool,
  isSignUpCompleted: PropTypes.bool,
  openModalAndCloseOthers: PropTypes.func.isRequired,
};

FooterPaymentItemsUI.defaultProps = {
  items: {},
  isUserLoggedIn: false,
  isUserRegistered: false,
  isSignUpCompleted: false,
};

export const FooterPaymentItems = withCoreComponent(
  PaymentsCore,
  withModalActions(
    withUser(FooterPaymentItemsUI, [
      USER_FIELDS.IS_USER_LOGGED_IN,
      USER_FIELDS.IS_USER_REGISTERED,
      USER_FIELDS.IS_SIGN_UP_COMPLETED,
    ])
  )
);
