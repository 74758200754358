import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withLocale,
  withModalActions,
  withUser,
  withUserAgent,
} from 'core/hocs';
import { USER_FIELDS } from 'core/constants';
import { wrapRoute2Locale } from 'core/helpers';

import {
  MODAL_IDS,
} from '../../constants';

class DepositModalWrapper extends Component {
  componentDidMount() {
    const {
      location: {
        search,
      },
      history,
      locale,
      isPwa,
      isUserLoggedIn,
      openModalAndCloseOthers,
      isUserRegistered,
      isSignUpCompleted,
    } = this.props;

    let modalId;

    if (!isUserRegistered && !isUserLoggedIn) {
      modalId = MODAL_IDS.SIGN_UP;
    } else if (isUserRegistered && !isUserLoggedIn) {
      modalId = MODAL_IDS.SIGN_IN;
    } else if (isUserLoggedIn && !isSignUpCompleted) {
      modalId = MODAL_IDS.COMPLETE_SIGN_UP;
    } else if (isUserLoggedIn && isSignUpCompleted) {
      modalId = MODAL_IDS.DEPOSIT;
    }

    const redirectUrl = wrapRoute2Locale(`/${search}${isPwa ? '?launch_from=pwa' : ''}`, locale);

    openModalAndCloseOthers(modalId);

    history.push(redirectUrl);
  }

  render() {
    return null;
  }
}

DepositModalWrapper.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  locale: PropTypes.string.isRequired,
  isPwa: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  isUserRegistered: PropTypes.bool.isRequired,
  isSignUpCompleted: PropTypes.bool.isRequired,
  openModalAndCloseOthers: PropTypes.func.isRequired,
};

export default withModalActions(
  withLocale(
    withUserAgent(
      withUser(
        DepositModalWrapper, [
          USER_FIELDS.IS_USER_LOGGED_IN,
          USER_FIELDS.IS_SIGN_UP_COMPLETED,
          USER_FIELDS.IS_USER_REGISTERED,
        ]
      )
    )
  )
);
