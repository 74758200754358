import React, {
  Fragment, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import {
  FormattedTag,
  FormCompleteSignUp as FormCompleteSignUpCore,
} from 'core/components';
import {
  withCoreComponent, withModalActions, withFormDataActions, withUser,
} from 'core/hocs';
import { getRandomPhoneNumberWithPrefix } from 'helpers/tel';
import { FORM_FIELDS, FORM_ACTIONS } from 'core/constants';
import { FormElement } from 'components/form-element/form-element';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { IconSuccessBig } from 'components/icons/icon-notification/icon-success-big';
import { MODAL_IDS } from '../../../constants';

import './form-complete-sign-up.scss';

const STEPS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

const DEFAULT_COUNTRY = 'MX';

const FIELDS_NAMES_LIST = [
  FORM_FIELDS.FIRST_NAME,
  FORM_FIELDS.LAST_NAME,
];

export const FormCompleteSignUpUI = ({
  setFormData,
  userMobilePhone,
  countryInfo,
  isSmsConfirmationActive,
  isSuccess,
  isLongForm,
  validateField,
  onSubmit,
  addExtraData,
  closeModal,
  openModalAndCloseOthers,
  closeAllModals,
  steps,
  isSignUpCompleted,
  isInProgress,
  isStepFilled,
  mobile,
}) => {
  const handleSubmit = useCallback((e) => {
    if (isSmsConfirmationActive && !userMobilePhone) {
      addExtraData({ isFakeMobile: true });
    }

    onSubmit(e);
  }, []);

  const handleCloseModal = useCallback(() => {
    closeModal(MODAL_IDS.COMPLETE_SIGN_UP);
  }, []);

  const validateDocNumberField = (val, item) => {
    if (val.length > 18) {
      return true;
    }

    setFormData(item.formAction, { [item.name]: val });

    validateField(item.name, val);

    return true;
  };

  const handleValidateField = (val, item) => {
    setFormData(item.formAction, { [item.name]: val });

    validateField(item.name, val);

    return true;
  };

  const openDepositModal = () => {
    openModalAndCloseOthers(MODAL_IDS.DEPOSIT);
  };

  useEffect(() => {
    const {
      phonePrefix
    } = countryInfo;

    setFormData(FORM_ACTIONS.COMPLETE_SIGN_UP, {
      [FORM_FIELDS.COUNTRY]: DEFAULT_COUNTRY,
    });

    if (isSmsConfirmationActive) {
      // used for users who were registered before the introduction of SMS confirmation
      const randomNumber = getRandomPhoneNumberWithPrefix(phonePrefix);

      setFormData(FORM_ACTIONS.COMPLETE_SIGN_UP, {
        [FORM_FIELDS.MOBILE]: userMobilePhone || randomNumber,
      });
    }
  }, []);

  useEffect(() => {
    if (isSuccess && !isLongForm && isSignUpCompleted) {
      handleCloseModal();
      openDepositModal();
    }
  }, [isSuccess, isSignUpCompleted]);

  if (isSuccess && isLongForm) {
    return (
      <div className="text-center mt-13 mt-sm-0 pt-5 pt-sm-0">
        <IconSuccessBig className="mb-3 mt-1 mt-sm-0 mt-lg-1" />

        <FormattedTag
          className="h2 text-center d-block font-weight-strong mb-1 text-blue-dark"
          id="form.complete-sign-up.profile-completed-withdrawal"
        />

        <FormattedTag
          className="mb-3 mt-1 px-4 text-base d-block text-blue-dark"
          id="form.complete-sign-up.information-saved-withdrawal"
        />

        <Button color="primary" className="w-100 mt-1" onClick={closeAllModals}>
          <FormattedMessage
            id="form.complete-sign-up.success-view-btn"
          />
        </Button>
      </div>
    );
  }

  const stepForm = (
    <Form onSubmit={handleSubmit}>
      {!isLongForm && Object.values(steps[STEPS.FIRST]).map(
        item =>
          FIELDS_NAMES_LIST.includes(item.name) && (
            <FormElement
              key={item.name}
              secondaryPlaceholder={item.name === FORM_FIELDS.BIRTH_DATE && 'form.fields.birth-date.mask'}
              coreProps={{ item, handleChange: handleValidateField }}
            />
          ),
      )}

      {isLongForm && (
        <>
          <FormattedTag
            tag="div"
            id="form.fields.birth-date"
            className="form-element-label text-base d-inline-block text-gray-10 mb-1"
          />

          <div className="complete-sign-up-form-dates d-flex justify-content-between w-100">
            <FormElement coreProps={{ item: steps[STEPS.FIRST].year }} />

            <FormElement className="mx-1_5" coreProps={{ item: steps[STEPS.FIRST].month }} />

            <FormElement coreProps={{ item: steps[STEPS.FIRST].day }} />
          </div>

          <FormElement coreProps={{ item: steps[STEPS.FIRST].gender }} />

          <FormElement
            coreProps={{ item: steps[STEPS.FIRST].docNumber, handleChange: validateDocNumberField }}
          />

          <FormElement coreProps={{ item: steps[STEPS.FIRST].city, handleChange: handleValidateField }} />

          <FormElement coreProps={{ item: steps[STEPS.FIRST].postalCode }} />

          <FormElement coreProps={{ item: steps[STEPS.FIRST].address }} />

          {!isSmsConfirmationActive && !userMobilePhone && (
            <FormElement coreProps={{ item: steps[STEPS.FIRST].mobile }} />
          )}
        </>
      )}

      <ButtonWithLoader
        isLoading={isInProgress}
        className="mt-1 w-100"
        color="primary"
        type="submit"
        disabled={!isStepFilled}
      >
        <FormattedMessage id="confirm" />
      </ButtonWithLoader>
    </Form>
  );

  return (
    <Fragment>
      <div className="text-center">
        <FormattedTag
          className="h3 text-blue-dark text-uppercase"
          id="form.complete-sign-up.complete-profile"
        />
        <FormattedTag
          className="mb-2_5 mt-1 pt-0_25 px-3 text-small d-block text-blue-dark"
          id={isLongForm ? 'form.complete-sign-up.fill-profile-withdrawal' : 'form.complete-sign-up.fill-profile'}
          values={{ mobile }}
          isHtml
        />
      </div>

      {stepForm}
    </Fragment>
  );
};

FormCompleteSignUpUI.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  steps: PropTypes.shape().isRequired,
  countryInfo: PropTypes.shape().isRequired,
  isInProgress: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isStepFilled: PropTypes.bool.isRequired,
  isSmsConfirmationActive: PropTypes.bool,
  mobile: PropTypes.string,
  userMobilePhone: PropTypes.string,
  addExtraData: PropTypes.func.isRequired,
  openModalAndCloseOthers: PropTypes.func.isRequired,
  closeAllModals: PropTypes.func.isRequired,
  isSignUpCompleted: PropTypes.bool.isRequired,
  formData: PropTypes.shape({
    country: PropTypes.string,
  }),
  validateField: PropTypes.func.isRequired,
  isLongForm: PropTypes.bool,
};

FormCompleteSignUpUI.defaultProps = {
  mobile: null,
  userMobilePhone: null,
  isSmsConfirmationActive: false,
  formData: PropTypes.shape({
    country: PropTypes.string,
  }),
  isLongForm: false
};

export const FormCompleteSignUp = withCoreComponent(
  FormCompleteSignUpCore,
  withModalActions(
    withFormDataActions(
      withUser(FormCompleteSignUpUI),
    ),
  ),
);
